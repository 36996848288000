<!-- 分类商品 -->
<template>
  <page-layout>
    <div slot="action">
      <div class="buy_cart" @click="$router.push({ path: 'buyCart' })">
        购物车<a-badge
          :count="cartNum"
          :number-style="{
            backgroundColor: 'transparent',
            color: '#3ab887',
            boxShadow: '0 0 0 1px #3ab887 inset',
            marginLeft: '9px',
            top: '-1px',
          }"
        />
      </div>
    </div>
    <!-- <div slot="headerContent">
            <div class="top">
                <div :class="query.good_type == 1 ? 'active' : ''" @click="chooise(1)">
                    单品商品
                </div>
                <div :class="query.good_type == 2 ? 'active' : ''" @click="chooise(2)">
                    套餐商品
                </div>
            </div>
        </div> -->
    <div id="mealGoods">
      <div class="middle">
        <cate-selector
          style="margin-bottom: 12px"
          :dataArray="categoryList"
          @onSelectedValueChange="selectorChange"
        />
        <div class="classify_box">
          <span class="pd210">商品排序：</span>
          <span class="classify">
            <span
              :class="use == 1 ? 'active' : ''"
              @click="sort('comprehensive')"
            >
              综合排序
            </span>
            <span :class="use == 2 ? 'active' : ''" @click="sort('price')">
              按价格
              <a-icon type="caret-up" v-if="use == 2 && query.price == 'asc'" />
              <a-icon
                type="caret-down"
                v-if="use == 2 && query.price == 'desc'"
              />
            </span>
            <!-- <span :class="use == 3 ? 'active' : ''" @click="sort('sales')">
              按销量
              <a-icon type="caret-up" v-if="use == 3 && query.sales == 'asc'" />
              <a-icon
                type="caret-down"
                v-if="use == 3 && query.sales == 'desc'"
              />
            </span> -->
          </span>
        </div>
        <div>
          <a-row
            :gutter="[16, 16]"
            type="flex"
            justify="space-between"
            align="middle"
            class="font"
          >
            <a-col>
              <a-form
                :model="query"
                layout="inline"
                style="font-size: 12px !important"
              >
                <!-- <a-form-item label="出版社" v-show="query.good_type == 1">
                                    <a-input v-model="query.publishing_house" placeholder="出版社搜索" />
                                </a-form-item>
                                <a-form-item label="作者" v-show="query.good_type == 1">
                                    <a-input v-model="query.author" placeholder="作者搜索" />
                                </a-form-item>
                                <a-form-item label="发版日期" v-show="query.good_type == 1">
                                    <a-range-picker @change="onChange" v-model="time" style="width: 186px" />
                                </a-form-item>
                                <a-form-item label="开本" v-show="query.good_type == 1">
                                    <a-select v-model="query.format" style="width: 150px" placeholder="选择开本">
                                        <a-select-option value="0"> 不限 </a-select-option>
                                        <a-select-option value="16"> 16开 </a-select-option>
                                        <a-select-option value="32"> 32开 </a-select-option>
                                        <a-select-option value="64"> 64开 </a-select-option>
                                    </a-select>
                                </a-form-item> -->
                <a-form-item label="价格区间" class="font14">
                  ￥<a-input-number v-model="minValue" :min="0" /> -
                  ￥<a-input-number v-model="maxValue" :min="minValue" />
                  <a-button
                    type="primary"
                    style="margin-left: 12px"
                    @click="screen"
                  >
                    筛选
                  </a-button>
                  <a-button
                    style="margin-left: 12px"
                    @click="
                      (query.price_section = ''), (minValue = 0), (maxValue = 0)
                    "
                  >
                    重置
                  </a-button>
                </a-form-item>
              </a-form>
            </a-col>
            <a-col>
              <a-input-search
                style="margin-left: auto"
                :placeholder="
                  genre == 3 ? '请输入商品名称' : '请输入Isbn编号/商品名称'
                "
                enter-button
                v-model="query.keywords"
                @search="onSearch"
              />
            </a-col>
          </a-row>
        </div>
      </div>
      <a-spin :spinning="spinning">
        <div class="bottom">
          <!-- <a-row :gutter="[16, 16]" v-if="query.good_type != 2">
            <a-col
              class="gutter-row"
              :xxl="12"
              v-for="item in list"
              :key="item.product_id"
            >
              <div
                class="list-box"
                @click="
                  $router.push({
                    path: 'goodsDetail',
                    query: { id: item.product_id },
                  })
                "
              >
                <div class="">
                  <img :src="item.image" alt="" />
                </div>
                <div class="content_box">
                  <div class="title">{{ item.store_name }}</div>
                  <div class="title1">{{ item.store_info }}</div>
                  <div class="book_situation">
                    <div class="box">
                      <div class="num">{{ item.current_number }}</div>
                      <div class="text">书籍数量</div>
                    </div>
                    <div class="box">
                      <div class="num">{{ item.kind }}</div>
                      <div class="text">书籍种类</div>
                    </div>
                    <div class="box">
                      <div class="num">12h</div>
                      <div class="text">发货时间</div>
                    </div>
                    <div class="box">
                      <div class="num">{{ item.cate_name }}</div>
                      <div class="text">分类名称</div>
                    </div>
                  </div>
                  <div class="money_box">
                    <div class="current">{{ item.price | price }}元</div>
                    <div class="original">
                      <del>原价:{{ item.ot_price | price }}</del>
                      <span>&nbsp;/&nbsp;已售{{ item.sales }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row> -->
          <a-row class="list_box" :gutter="[16, 16]">
            <a-col
              class="list"
              :xl="6"
              :xxl="4"
              :sm="12"
              v-for="item in list"
              :key="item.product_id"
            >
              <a-card
                hoverable
                @click="
                  $router.push({
                    path: 'goodsDetail',
                    query: { id: item.product_id },
                  })
                "
              >
                <div slot="cover" style="text-align: center">
                  <div style="height: 224px">
                    <img alt="example" :src="item.image" />
                  </div>
                </div>
                <a-card-meta>
                  <template slot="title">
                    <span class="presell" v-if="item.is_oversold == 1">
                      预售
                    </span>
                    {{ item.store_name }}
                  </template>
                  <template slot="description">
                    <div class="box_button">
                      <div>
                        <div
                          class="money"
                          v-if="item.max_price && item.max_price != item.price"
                        >
                          ¥{{ item.price | price }}起
                        </div>
                        <div class="money" v-else>
                          ¥{{ item.price | price }}
                        </div>
                        <del class="del"> ¥{{ item.ot_price | price }} </del>
                      </div>
                      <a-button
                        type="primary"
                        size="small"
                        @click.stop="openCardNum(item)"
                      >
                        {{
                          item.good_type == 3 ||
                          item.is_cust == 1 ||
                          item.pay_type_info.is_dandu == 1
                            ? "立即购买"
                            : "加入购物车"
                        }}
                      </a-button>
                      <!-- <a-button class="button" @click="
                                                $router.push({
                                                    path: 'goodsDetail',
                                                    query: { id: item.product_id },
                                                })
                                            ">查看详情</a-button> -->
                    </div>
                  </template>
                </a-card-meta>
              </a-card>
            </a-col>
          </a-row>
          <div
            class="nodata"
            style="text-align: center"
            v-show="list.length == 0"
          >
            <img
              src="@/assets/img/haveNoData.png"
              style="width: 200px; heigth: 200px"
              alt=""
            />
            <div>暂无相关商品</div>
          </div>
          <div style="margin-top: 20px; text-align: right">
            <a-pagination
              v-model="query.page"
              :page-size-options="pageSizeOptions"
              :page-size="query.limit"
              :total="total"
              show-size-changer
              v-if="total != 0"
              @change="onChange1"
              @showSizeChange="onShowSizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-spin>
    </div>
    <cart-num
      ref="cartNum"
      :list="specificationList"
      :minNumber="minNumber"
      :maxNumber="maxNumber"
      :type="
        addGood.good_type == 3 ||
        addGood.is_cust == 1 ||
        (addGood.pay_type_info && addGood.pay_type_info.is_dandu == 1)
          ? 1
          : 0
      "
      @addCart="addCarts"
    />
    <shopping-cart-fixed
      class="fixed"
      ref="shoppingCart"
      @getCartNum="updateCart"
    />
  </page-layout>
</template>

<script>
import CateSelector from "./CateSelector";
import cartNum from "@/components/addCartNum";
import PageLayout from "@/layouts/PageLayout";
import {
  addCart,
  cartCount,
  productList,
  category,
  detail,
} from "@/api/allGoods";
import shoppingCartFixed from "@/components/shoppingCart/index-fixed";
import { price } from "@/utils/filters";
import { mapState } from "vuex";

export default {
  name: "allProducts",
  props: {
    genre: {
      default: 0, // 0-所有 1-商品 2-物资 3-服务费
      type: Number,
      validator: function (value) {
        return [0, 1, 2, 3].includes(value);
      },
    },
    good_type: {
      default: 1, // 1-单品 2-套餐
      type: Number,
    },
    // classId: {
    //   default: 0, // 1-商品 2-物资,服务费
    //   type: Number,
    // },
  },
  components: { CateSelector, PageLayout, shoppingCartFixed, cartNum },
  filters: { price },
  data() {
    return {
      spinning: false,
      use: 1,
      query: {
        genre: 1,
        good_type: 1,
        page: 1,
        limit: 12,
        cate_id: "",
        price_section: "0",
        keywords: "",
        comprehensive: "desc",
        price: "",
        sales: "",
      },
      pageSizeOptions: ["12", "24", "36", "48", "60"],
      total: 0,
      list: [], // 商品数组
      categoryList: [], //分类
      addGood: {}, // 要加入购物车/立即购买的商品
      specificationList: [], // 要加入购物车/立即购买的规格信息
      minValue: 0,
      maxValue: 0,
      minNumber: 1,
      maxNumber: 9999,
    };
  },
  computed: {
    ...mapState({
      cartNum: (state) => {
        return state.cart.total;
      },
    }),
  },
  created() {
    this.query.good_type = this.good_type;
    this.query.genre = this.genre;
    if (JSON.stringify(this.$route.query) != "{}") {
      this.query.genre = 0;
      this.query.keywords = this.$route.query.keywords;
      this.query.good_type = 0;
    }
  },
  mounted() {
    this.getCate();
  },
  methods: {
    screen() {
      this.query.price_section = this.minValue + "|" + this.maxValue;
      this.getList();
    },
    openCardNum(record) {
      //获取多规格信息
      const hide = this.$message.loading("请稍等...", 0);
      this.getProductDetail(record.product_id)
        .then((res) => {
          this.addGood = record;
          this.specificationList = res;
          this.$refs.cartNum.visible = true;
        })
        .finally(() => {
          hide();
        });
    },
    getProductDetail(id) {
      return new Promise((resolve, reject) => {
        detail(id)
          .then((res) => {
            this.minNumber = res.data.data.storeInfo.minimum_quantity;
            this.maxNumber =
              res.data.data.storeInfo.is_purchase == 1
                ? res.data.data.storeInfo.purchase_limit
                : 9999;
            resolve(res.data.data.productValue);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addCarts(addInfo) {
      addCart({
        productId: this.addGood.product_id,
        cartNum: addInfo.value,
        uniqueId: addInfo.uniqueId,
        is_new: addInfo.type,
      }).then((res) => {
        if (addInfo.type == 0) {
          // 加入购物车
          this.$message.success("已加入购物车");
          this.$refs.cartNum.visible = false;
          this.$refs.cartNum.form.value = 1;
          this.$store.dispatch("cart/getNum");
          this.$store.dispatch("cart/getList", {
            page: this.query.page,
            limit: this.query.limit,
          });
        } else {
          // 立即购买
          this.$router.push({
            path: "submitOrder",
            query: { cartId: res.data.data.cartId, new: 1 },
          });
        }
      });
    },
    updateCart() {
      // this.getCartNum();
      this.$store.dispatch("cart/getList", {
        page: this.query.page,
        limit: this.query.limit,
      });
      this.$store.dispatch("cart/getNum");
    },
    onSearch(e) {
      this.query.keywords = e;
      this.getList();
    },
    getCate() {
      category({
        pid: 0,
        level: 0,
        type: 1,
      }).then((res) => {
        // genre 1-商品 2-物资 3-服务费
        // good_type 1-单品 2-套餐
        if (this.genre == 1) {
          if (this.good_type == 1) {
            this.categoryList = res.data.data.filter(
              (item) =>
                item.cate_name.includes("图书") &&
                !item.cate_name.includes("套餐")
            );
          } else {
            this.categoryList = res.data.data.filter((item) =>
              item.cate_name.includes("图书套餐")
            );
          }
        } else if (this.genre == 2) {
          if (this.good_type == 1) {
            this.categoryList = res.data.data.filter(
              (item) =>
                item.cate_name.includes("物资") &&
                !item.cate_name.includes("套餐")
            );
          } else {
            this.categoryList = res.data.data.filter((item) =>
              item.cate_name.includes("物资套餐")
            );
          }
        } else if (this.genre == 3) {
          this.categoryList = res.data.data.filter((item) =>
            item.cate_name.includes("服务费")
          );
        } else {
          this.categoryList = res.data.data;
        }
        if (this.genre == 0) {
          this.selectorChange([{}]);
        } else {
          this.selectorChange([this.categoryList[0] || {}]);
        }
      });
    },
    selectorChange(val) {
      console.log("selectorChange", val);
      this.query.cate_id = val[val.length - 1].id || 0;
      this.getList();
    },
    sort(value) {
      if (value == "comprehensive") {
        this.use = 1;
        this.query.comprehensive =
          this.query.comprehensive == "asc" ? "desc" : "asc";
        this.query.price = "";
        this.query.sales = "";
      }
      if (value == "price") {
        this.use = 2;
        this.query.price = this.query.price == "asc" ? "desc" : "asc";
        this.query.comprehensive = "";
        this.query.sales = "";
      }
      if (value == "sales") {
        this.use = 3;
        this.query.sales = this.query.sales == "asc" ? "desc" : "asc";
        this.query.price = "";
        this.query.comprehensive = "";
      }
      this.getList();
    },
    getList() {
      this.spinning = true;
      productList(this.query)
        .then((res) => {
          if (res.data.data.count == 0) {
            this.total = 0;
            this.list = [];
          } else {
            this.list = res.data.data.list;
            this.total = res.data.data.count;
          }
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    addCart(id, unique) {
      cartCount().then((res) => {
        this.cartNum = res.data.data.count;
        addCart({
          productId: id,
          cartNum: this.cartNum,
          uniqueId: unique,
          is_new: 0,
        }).then((res) => {
          this.$message.success(res.data.msg);
        });
      });
    },
    onChange1(page, pageSize) {
      this.query.page = page;
      this.query.limit = pageSize;
      this.getList();
    },
    onShowSizeChange(current, pageSize) {
      this.query.page = current;
      this.query.limit = pageSize;
      this.getList();
    },
  },
};
</script>

<style lang="less" scope>
@import "meal";
</style>
<style scoped>
.fixed {
  position: fixed;
  right: 32px;
  bottom: 60px;
  z-index: 3;
}
</style>
<style>
#mealGoods .bottom .list_box .list .ant-card-body .ant-card-meta-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /*autoprefixer: off;*/
  /*autoprefixer: on;*/
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  height: 42px;
}
</style>
