<template>
  <div>
    <div v-if="myDataArray.length" class="content">
      <div class="title">{{ level }}级分类:</div>
      <div class="tag-container">
        <div v-for="item in myDataArray" :key="item.id">
          <span
            :class="['boxs', item.checked ? 'select' : '']"
            @click="onSelectItem(item)"
          >
            {{ item.cate_name }}
          </span>
        </div>
      </div>
    </div>
    <cate-selector
      v-if="
        selectedItem &&
        selectedItem.checked &&
        selectedItem.children &&
        selectedItem.children.length
      "
      :dataArray="selectedItem.children"
      :selectedIds="selectedIds"
      :level="level + 1"
      @onSelectedValueChange="onSelectedValueChange"
    />
  </div>
</template>

<script>
export default {
  name: "CateSelector",
  props: {
    dataArray: {
      type: Array,
      default: () => [],
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      myDataArray: [],
      mySelectedIds: [],
      selectedItem: {},
    };
  },
  watch: {
    selectedIds: {
      handler(val) {
        this.mySelectedIds = [...val];
        this.setSelectedItems();
      },
      immediate: true,
    },
    dataArray: {
      handler(val) {
        this.myDataArray = [...val];
        this.setFirstAllItem(this.myDataArray);
        this.setSelectedItems();
      },
      immediate: true,
    },
  },
  methods: {
    setFirstAllItem(array, pItem = {}) {
      if (array.length > 1 && array[0].cate_name != "全部") {
        array.unshift({
          id: pItem.id || array[0].pid || 0,
          pid: array[0].pid || 0,
          cate_name: "全部",
          pic: "",
          type: array[0].type || 1,
          children: [],
        });
      }
      array.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.setFirstAllItem(item.children, item);
        }
      });
    },
    setSelectedItems() {
      this.myDataArray.forEach((item) => {
        this.$set(item, "checked", this.mySelectedIds.includes(item.id));
        item.children = item.children || [];
      });
      if (
        this.myDataArray.length > 0 &&
        this.myDataArray.filter((item) => item.checked).length == 0
      ) {
        this.myDataArray[0].checked = true;
      }
      this.selectedItem = this.myDataArray.find((item) => item.checked);
    },
    // 选中
    onSelectItem(
      item,
      parentArray = this.myDataArray,
      selectArray = [],
      once = true
    ) {
      parentArray.forEach((element) => {
        if (element.id != item.id) {
          this.onDeselectItem(element);
        }
      });
      this.$set(item, "checked", true);
      if (once) {
        this.selectedItem = item;
      }
      selectArray = [...selectArray, item];
      if (item.children && item.children.length > 0) {
        this.onSelectItem(item.children[0], item.children, selectArray, false);
      } else {
        this.$emit("onSelectedValueChange", selectArray);
      }
    },
    // 取消选中
    onDeselectItem(item) {
      this.$set(item, "checked", false);
      (item.children || []).forEach((element) => {
        if (element.checked) {
          this.onDeselectItem(element);
        }
      });
    },
    onSelectedValueChange(val) {
      this.$emit("onSelectedValueChange", [
        this.myDataArray.find((item) => item.checked),
        ...val,
      ]);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  align-items: baseline;
}
.title {
  flex: 60px;
  font-size: 14px;
  color: #383838;
  margin-right: 12px;
}
.tag-container {
  flex: calc(100% - 60px);
  line-height: 40px;
  display: flex;
  flex-wrap: wrap;
  // background-color: rgba(58, 184, 135, 0.1);
  margin: 5px 0px;
  padding: 0 8px;
  border-radius: 8px;
}

.boxs {
  font-size: 14px;
  padding: 4px 10px;
  margin-right: 12px;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  color: #383838;
  transition: all 0.3s;
}
.select {
  background-color: @primary-color;
  color: #fff;
}
</style>
